<template>
    <div v-if="me" class="edit-style">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('STYLE')"
            :show-title="showHeaderTitle"
        />
        <main class="main" @scroll="onScroll">
            <div class="page-header m-b-40">
                <div class="title" v-html="$translate('STYLE')" />
            </div>
            <Slider
                ref="ageSlider"
                :title="'나이'"
                :range="ageValue"
                :unit="'세'"
                :slider-options="ageSliderOptions"
                @change="updateStyleChanged"
            />
            <Slider
                ref="heightSlider"
                :title="'키'"
                :range="heightValue"
                :unit="'cm'"
                :slider-options="heightSliderOptions"
                @change="updateStyleChanged"
            />
            <div class="height-warning m-t-8 m-l-16" v-html="$translate('EDIT_HEIGHT_WARNING')" />
            <div class="types">
                <div @click="editType(type.key)" class="item" :key="type.key" v-for="type in types">
                    <div class="key" v-html="$translate(type.title)" />
                    <div class="value" v-html="$translate(type.value)" />
                </div>
            </div>
            <hr />
            <EditIntro :childOf="'style'" v-model="style.intro" />
        </main>
    </div>
</template>

<script>
import EditIntro from './components/EditIntro'
import userService from '@/services/user'

export default {
    name: 'EditStylePage',
    components: { EditIntro },
    data: () => ({
        ageValue: [],
        ageSliderOptions: {
            min: 20,
            max: 49,
            tooltip: 'none',
        },
        heightValue: [],
        heightSliderOptions: {
            min: 140,
            max: 199,
            tooltip: 'none',
        },
        types: [],
        styleChanged: false,
        showHeaderTitle: false,
    }),
    watch: {
        style: {
            deep: true,
            handler: 'updateStyleChanged',
        },
    },
    created() {
        this.init()
    },
    beforeDestroy() {
        this.save()
        if (this.styleChanged) this.$toast.success('SAVED')
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        style() {
            return this.me.style || {}
        },
    },
    methods: {
        initTypes() {
            this.types = ['friend', 'spec', 'smoking'].map(key => ({
                key,
                title: `${key.toUpperCase()}_TYPE`,
                value: this.typeTitle(key),
            }))
        },
        init() {
            if (!this.me) return

            this.ageValue = [
                this.style.min_age || this.ageSliderOptions.min,
                this.style.max_age || this.ageSliderOptions.max,
            ]
            this.heightValue = [
                this.style.min_height || this.heightSliderOptions.min,
                this.style.max_height || this.heightSliderOptions.max,
            ]

            this.initTypes()
        },
        async save() {
            try {
                this.saveSliderData()
                await userService.createStyles(this.style)
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            } catch (e) {
                this.$toast.error('선호하는 외모, 스펙, 흡연여부를 모두 입력해주세요')
            }
        },
        saveSliderData() {
            this.style.min_age = this.$refs.ageSlider.myRange[0]
            this.style.max_age = this.$refs.ageSlider.myRange[1]
            this.style.min_height = this.$refs.heightSlider.myRange[0]
            this.style.max_height = this.$refs.heightSlider.myRange[1]
        },
        typeTitle(key) {
            if (!this.me) return

            const value = (this.$vbConstants.types[key].find(t => t.value === this.style[`${key}_type`]) || {}).title

            if (value) {
                return `<span class="c-primary">${value}</span>`
            } else {
                return `<span class="c-text-lightest">입력해주세요</span>`
            }
        },
        editType(key) {
            this.$modal
                .custom({
                    component: 'ModalEditStyleType',
                    options: {
                        key,
                    },
                })
                .then(item => {
                    if (!item) return

                    this.style[`${key}_type`] = item.value
                    this.initTypes()
                })
        },
        updateStyleChanged() {
            this.styleChanged = true
        },
        onScroll(event) {
            if (event.target.scrollTop >= 52) {
                this.showHeaderTitle = true
            } else {
                this.showHeaderTitle = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-style {
    $header-height: 52px;
    overflow: hidden;

    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }

    .main {
        height: calc(100vh - #{$header-height});
        padding-bottom: 120px;
        overflow-y: auto;
    }

    .types {
        margin-top: 40px;

        .item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 16px 20px;
        }

        .key {
            @include f-medium;
            font-size: 16px;
            color: black;
        }

        .value {
            @include f-medium;
            font-size: 14px;
        }
    }

    .height-warning {
        color: $pink-deep;
        letter-spacing: -0.2px;
        line-height: normal;
        font-size: 12px;
        @include f-regular;
    }

    hr {
        margin: 32px 0;
        background: $grey-02;
        height: 1px;
        border-bottom: none;
    }
}
</style>
