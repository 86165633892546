<template>
    <div class="edit-intro">
        <div class="title m-b-24" v-html="$translate(`EDIT_INTRO_TITLE_${childOf.toUpperCase()}`)" />
        <div class="textarea-wrapper">
            <textarea @input="intro = $event.target.value" :value="intro" :placeholder="placeholder" />
            <i v-show="(intro || '').length > 0" class="material-icons" @click="intro = ''"> cancel </i>
        </div>
        <div class="warning" v-html="$translate(`EDIT_INTRO_WARNING_${childOf.toUpperCase()}`)" />
    </div>
</template>

<script>
export default {
    name: 'EditIntro',
    props: ['childOf', 'value'],
    data: () => ({
        intro: '',
        introChanged: false,
    }),
    watch: {
        intro(newVal, oldVal) {
            this.$emit('input', newVal)
            this.introChanged = !!oldVal
        },
    },
    computed: {
        placeholder() {
            if (this.childOf === 'profile') return '성격, 연애 스타일, 매력 포인트 등'
            if (this.childOf === 'style') return '외모, 성격, 학력, 거리, 종교 등'

            return ''
        },
    },
    mounted() {
        this.intro = this.value
    },
    beforeDestroy() {
        if (this.introChanged) this.$toast.success('SAVED')
    },
}
</script>

<style lang="scss" scoped>
$edit-profile-padding: 16px;
.edit-intro {
    padding: 0 $edit-profile-padding;

    .title {
        color: black;
        font-size: 18px;

        @include f-medium;
    }

    .items {
        .badge-desc {
            display: table;
            padding: 2px 6px;
            background: $grey-02;
            border-radius: 6px;
            font-family: NotoSans-Medium;
            font-size: 11px;
            margin-bottom: 4px;
        }

        color: $grey-07;
        font-size: 12px;

        @include f-medium;

        .item {
            display: inline-block;

            &:not(:last-child)::after {
                content: 'ㅣ';
                color: $grey-04;
            }
        }
    }

    .textarea-wrapper {
        margin: 8px 0;
        min-height: 144px;
        display: flex;

        &:focus-within {
            border: solid 1px $purple-primary;
        }

        textarea {
            margin: 0 16px 0 0;
            padding: 0;
            line-height: 1.6;
        }

        .material-icons {
            color: $grey-05;
            font-size: 20px;
        }
    }

    .warning {
        font-size: 12px;
        color: $grey-06;
    }

    // 프로필 수정에 있는 자기소개 스타일
    &.profile {
        .title {
            margin-bottom: 24px;
        }

        .warning {
            color: $grey-06;
        }
    }
}
</style>
